import { isServer } from '@teamsparta/utils';

export function getSessionStorageItem<T>(
  key: string,
  defaultValue?: T,
): T | undefined {
  if (isServer()) {
    return defaultValue;
  }

  try {
    const storedValue = JSON.parse(sessionStorage.getItem(key) || '""');
    return storedValue ?? defaultValue;
  } catch (error) {
    console.error(error);
    return defaultValue;
  }
}

export function setSessionStorageItem<T>(key: string, value: T) {
  if (isServer()) {
    return;
  }

  try {
    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(error);
  }
}

export function removeSessionStorageItem(key: string) {
  if (isServer()) {
    return;
  }

  try {
    sessionStorage.removeItem(key);
  } catch (error) {
    console.error(error);
  }
}
