import {
  infiniteQueryOptions,
  keepPreviousData,
  queryOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import type {
  GetCommunityPostDetailResponseDto,
  GetCommunityPostsRequestDto,
} from '@/api/community/post';
import {
  deleteCommunityPost,
  getCommunityNotices,
  getCommunityOngoingPollPosts,
  getCommunityPostDetail,
  getCommunityPosts,
  getPinnedCommunityPost,
  patchCommunityPost,
  patchCommunityPostPoll,
  patchCommunityPostViewIncrease,
  postCommunityPost,
} from '@/api/community/post';
import type { CommunityPostCategory } from '@/constants';
import { MINUTE } from '@/constants';

import { useAuthMutation } from '../common/auth';

export const communityPostQueryKey = {
  all: ['community-post'] as const,
  post: () => [...communityPostQueryKey.all, 'post'] as const,
  pinned: () => [...communityPostQueryKey.all, 'pinned'] as const,
  detail: (postId: string) =>
    [...communityPostQueryKey.post(), postId] as const,
  posts: (category: CommunityPostCategory) =>
    [...communityPostQueryKey.all, 'posts', category] as const,
  notices: () => [...communityPostQueryKey.all, 'notices'] as const,
  ongoingPollPosts: () =>
    [...communityPostQueryKey.all, 'ongoing-poll-posts'] as const,
  createPost: () => [...communityPostQueryKey.all, 'create-post'] as const,
  editPost: () => [...communityPostQueryKey.all, 'edit-post'] as const,
  deletePost: () => [...communityPostQueryKey.all, 'delete-post'] as const,
  patchPoll: () => [...communityPostQueryKey.all, 'patch-poll'] as const,
  postViewIncrease: () =>
    [...communityPostQueryKey.all, 'post-view-increase'] as const,
};

export const communityPostDetailQuery = ({
  postId,
  queryFn = () => getCommunityPostDetail({ postId }),
}: {
  postId: string;
  queryFn?: () => Promise<GetCommunityPostDetailResponseDto>;
}) =>
  queryOptions({
    queryKey: communityPostQueryKey.detail(postId),
    queryFn,
    enabled: Boolean(postId),
    refetchOnWindowFocus: false,
  });

export const COMMUNITY_POST_QUERY_EXPIRE_TIME = 20 * MINUTE;
export const communityPostsInfiniteQuery = ({
  category,
}: GetCommunityPostsRequestDto) =>
  infiniteQueryOptions({
    queryKey: communityPostQueryKey.posts(category),
    queryFn: ({ pageParam }: { pageParam: string | null }) =>
      getCommunityPosts({ cursor: pageParam ?? undefined, category }),
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    initialPageParam: null,
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
    gcTime: COMMUNITY_POST_QUERY_EXPIRE_TIME,
  });

export const pinnedCommunityPostQuery = () =>
  queryOptions({
    queryKey: communityPostQueryKey.pinned(),
    queryFn: getPinnedCommunityPost,
  });

export const communityNoticesQuery = () =>
  queryOptions({
    queryKey: communityPostQueryKey.notices(),
    queryFn: getCommunityNotices,
  });

export const communityOngoingPollPostsQuery = () =>
  queryOptions({
    queryKey: communityPostQueryKey.ongoingPollPosts(),
    queryFn: getCommunityOngoingPollPosts,
  });

export function usePostCommunityPost() {
  return useAuthMutation({
    mutationKey: communityPostQueryKey.createPost(),
    mutationFn: postCommunityPost,
  });
}

export function useDeleteCommunityPost() {
  return useAuthMutation({
    mutationKey: communityPostQueryKey.deletePost(),
    mutationFn: deleteCommunityPost,
  });
}

export function usePatchCommunityPost() {
  const queryClient = useQueryClient();

  return useAuthMutation({
    mutationKey: communityPostQueryKey.editPost(),
    mutationFn: patchCommunityPost,
    onSuccess: (postId) => {
      queryClient.invalidateQueries({
        queryKey: communityPostQueryKey.detail(postId),
      });
    },
  });
}

export function usePatchCommunityPostPoll() {
  return useAuthMutation({
    mutationKey: communityPostQueryKey.patchPoll(),
    mutationFn: patchCommunityPostPoll,
  });
}

export function usePatchCommunityPostViewIncrease() {
  return useMutation({
    mutationKey: communityPostQueryKey.postViewIncrease(),
    mutationFn: patchCommunityPostViewIncrease,
  });
}
