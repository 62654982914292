import { usePreserveCallback } from '@teamsparta/react';
import { noop } from '@teamsparta/utils';
import { useCallback, useEffect, useRef, useState } from 'react';

enum ConnectionStatus {
  IDLE = 'idle',
  CONNECTING = 'connecting',
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
  ERROR = 'error',
}

interface UseServerSentEventsProps<T> {
  /**
   * SSE 엔드포인트 URL
   */
  url: string;
  /**
   * 재연결 시도 간격 (밀리초)
   * @default 5000
   */
  reconnectTime?: number;
  /**
   * 자동 연결 여부
   * @default true
   */
  autoConnect?: boolean;
  /**
   * 자동 재연결 여부
   * @default true
   */
  autoReconnect?: boolean;
  /**
   * 메시지 수신 시 호출되는 콜백 함수
   */
  onMessage?: (data: T) => void;
  /**
   * 에러 발생 시 호출되는 콜백 함수
   */
  onError?: (error: Error) => void;
  /**
   * 연결 열릴 때 호출되는 콜백 함수
   */
  onOpen?: () => void;
  /**
   * 연결 닫힐 때 호출되는 콜백 함수
   */
  onClose?: () => void;
  /**
   * 구독할 이벤트 타입
   * 지정하지 않으면 기본 'message' 이벤트만 구독
   * 배열의 경우 상수 or 메모이제이션한 배열 형태로 전달
   */
  eventTypes?: string | string[];
  /**
   * 인증 헤더 포함 여부
   * @default true
   */
  withCredentials?: boolean;
}

interface UseServerSentEventsReturn<T> {
  /**
   * 서버에서 수신한 가장 최근 데이터
   */
  data: T | null;
  /**
   * 발생한 에러 객체
   */
  error: Error | null;
  /**
   * 현재 연결 상태
   */
  status: ConnectionStatus;
  /**
   * 연결 시도 중인지 여부
   */
  isPending: boolean;
  /**
   * 연결이 성공적으로 수립되었는지 여부
   */
  isSuccess: boolean;
  /**
   * 에러가 발생했는지 여부
   */
  isError: boolean;
  /**
   * 연결이 끊어졌는지 여부
   */
  isDisconnected: boolean;
  /**
   * 연결이 대기 상태인지 여부
   */
  isIdle: boolean;
  /**
   * SSE 연결을 수동으로 종료하는 함수
   */
  disconnect: () => void;
  /**
   * SSE 연결을 수동으로 시작하는 함수
   */
  connect: () => Promise<EventSource | null>;
}

const createEventSource = (url: string, withCredentials: boolean) =>
  new EventSource(url, {
    withCredentials,
  });

const createError = (error: unknown, defaultMessage: string): Error =>
  error instanceof Error ? error : new Error(defaultMessage);

/**
 * Server-Sent Events(SSE)를 위한 React 커스텀 훅
 */
export function useServerSentEvents<T>({
  url,
  autoConnect = true,
  autoReconnect = true,
  reconnectTime = 5000,
  onMessage = noop,
  onError = noop,
  onOpen = noop,
  onClose = noop,
  eventTypes,
  withCredentials = false,
}: UseServerSentEventsProps<T>): UseServerSentEventsReturn<T> {
  const eventSourceRef = useRef<EventSource | null>(null);
  const [serverData, setServerData] = useState<T | null>(null);
  const [connectionError, setConnectionError] = useState<Error | null>(null);
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>(
    ConnectionStatus.IDLE,
  );

  const onCloseCallback = usePreserveCallback(onClose);
  const onMessageCallback = usePreserveCallback(onMessage);
  const onErrorCallback = usePreserveCallback(onError);
  const onOpenCallback = usePreserveCallback(onOpen);

  const handleError = useCallback(
    (error: unknown, defaultMessage: string) => {
      const errorObj = createError(error, defaultMessage);
      setConnectionError(errorObj);
      setConnectionStatus(ConnectionStatus.ERROR);
      onErrorCallback(errorObj);
      return errorObj;
    },
    [onErrorCallback],
  );

  const disconnectSSE = useCallback(() => {
    if (eventSourceRef?.current) {
      eventSourceRef.current.close();
      eventSourceRef.current = null;
      setConnectionStatus(ConnectionStatus.DISCONNECTED);
      onCloseCallback?.();
    }
  }, [onCloseCallback]);

  const connectSSE = useCallback((): Promise<EventSource | null> => {
    return new Promise((resolve, reject) => {
      try {
        eventSourceRef.current = createEventSource(
          `${process.env.NEXT_PUBLIC_SERVER}${url}`,
          withCredentials,
        );
        setConnectionStatus(ConnectionStatus.CONNECTING);

        // let heartbeatTimeout: NodeJS.Timeout;

        // const resetHeartbeat = () => {
        //   if (heartbeatTimeout) {
        //     clearTimeout(heartbeatTimeout);
        //   }
        //   heartbeatTimeout = setTimeout(() => {
        //     console.warn('SSE heartbeat timeout');
        //     disconnectSSE();
        //     if (autoReconnect) {
        //       connectSSE();
        //     }
        //   }, 3 * SECOND);
        // };

        eventSourceRef.current.onopen = () => {
          setConnectionStatus(ConnectionStatus.CONNECTED);
          setConnectionError(null);
          // resetHeartbeat();
          onOpenCallback?.();
          resolve(eventSourceRef.current);
        };

        const handleEvent = (event: MessageEvent) => {
          try {
            const parsedData = JSON.parse(event.data) as T;
            setServerData(parsedData);
            onMessageCallback(parsedData);
          } catch (err) {
            handleError(err, 'Failed to parse SSE data');
          }
        };

        if (eventTypes) {
          const types = Array.isArray(eventTypes) ? eventTypes : [eventTypes];
          types.forEach((type) => {
            eventSourceRef.current?.addEventListener(type, handleEvent);
          });
        }

        eventSourceRef.current.onmessage = handleEvent;

        eventSourceRef.current.onerror = (error: Event) => {
          const errorObj = handleError(error, 'SSE connection error');
          eventSourceRef.current?.close();
          reject(errorObj);

          if (autoReconnect) {
            setTimeout(() => {
              connectSSE().catch(() => {}); // 재연결 시도 중 에러 무시
            }, reconnectTime);
          }
        };
      } catch (error) {
        const errorObj = handleError(error, 'Failed to create SSE connection');

        if (autoReconnect) {
          setTimeout(() => {
            connectSSE().catch(() => {}); // 재연결 시도 중 에러 무시
          }, reconnectTime);
        }
        reject(errorObj);
        return null;
      }
    });
  }, [
    url,
    withCredentials,
    eventTypes,
    onOpenCallback,
    onMessageCallback,
    handleError,
    autoReconnect,
    reconnectTime,
  ]);

  useEffect(() => {
    if (!autoConnect) {
      return;
    }

    connectSSE().then((eventSource) => {
      eventSourceRef.current = eventSource;
    });
  }, [connectSSE, autoConnect]);

  useEffect(() => {
    return () => disconnectSSE();
  }, [disconnectSSE]);

  return {
    data: serverData,
    error: connectionError,
    status: connectionStatus,
    isPending: connectionStatus === ConnectionStatus.CONNECTING,
    isSuccess: connectionStatus === ConnectionStatus.CONNECTED,
    isError: connectionStatus === ConnectionStatus.ERROR,
    isDisconnected: connectionStatus === ConnectionStatus.DISCONNECTED,
    isIdle: connectionStatus === ConnectionStatus.IDLE,
    disconnect: disconnectSSE,
    connect: connectSSE,
  };
}
