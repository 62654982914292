import styled from '@emotion/styled';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import { neutralDay } from '@teamsparta/design-system';

export const Content = styled(RadixTooltip.Content)`
  padding: 4px 8px;
  border-radius: 4px;
  background-color: ${neutralDay.gray90};
  z-index: 3;
`;
