import * as RadixTooltip from '@radix-ui/react-tooltip';

import * as StyledTooltip from './style';

export const Tooltip = {
  Provider: RadixTooltip.Provider,
  Root: RadixTooltip.Root,
  Trigger: RadixTooltip.Trigger,
  Content: StyledTooltip.Content,
  Portal: RadixTooltip.Portal,
};
