import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  hanghae,
  mBody1,
  mCaption3,
  neutralDark,
  neutralDay,
  Text,
} from '@teamsparta/design-system';
import Link from 'next/link';

import { DESKTOP, device } from '@/styles/themes';
import { applyOpacityToHexColor } from '@/styles/utils/applyOpacityToHexColor';
import { getVar } from '@/styles/utils/getVar';

const Stick = css`
  width: 18px;
  height: 2px;
  background-color: ${neutralDay.gray20};
  border-radius: 16px;
  transform-origin: 0.6px;
`;

export const HamburgerButton = styled.div<{ scrollDirection: string }>`
  display: flex;
  flex-direction: column;
  height: ${({ scrollDirection }) => (scrollDirection === 'up' ? '50px' : '0')};
  transition: all 0.3s ease;
  position: absolute;
  top: 18px;
  right: 21px;
  z-index: 20;
  cursor: pointer;

  @media ${device.desktop} {
    display: none;
  }
`;

export const FirstStick = styled.div<{ isOpen: boolean }>`
  ${Stick};
  transform: ${({ isOpen }) => (isOpen ? 'rotate(45deg)' : 'rotate(0)')};
  margin-bottom: 4px;
  transition: all 0.3s linear;
`;

export const SecondStick = styled.div<{ isOpen: boolean }>`
  ${Stick};
  transform: ${({ isOpen }) => (isOpen ? 'translateX(100%)' : 'translateX(0)')};
  opacity: ${({ isOpen }) => (isOpen ? 0 : 1)};
  margin-bottom: 4px;
`;

export const ThirdStick = styled.div<{ isOpen: boolean }>`
  ${Stick};
  transform: ${({ isOpen }) => (isOpen ? 'rotate(-45deg)' : 'rotate(0)')};
  transition: all 0.3s linear;
`;

export const RoundInfoBadge = styled.div<{
  isNew: boolean;
  isDisabled?: boolean;
  badgeText: string;
}>`
  ${mCaption3};
  line-height: 1;
  font-size: 11px;
  display: flex;
  align-items: center;
  padding: 1px 4px;
  height: 21px;
  border-radius: 4px;
  background-color: ${(props) =>
    props.badgeText === '모집 중'
      ? applyOpacityToHexColor(hanghae.red60, 0.2)
      : applyOpacityToHexColor(neutralDay.gray60, 0.2)};
  color: ${(props) =>
    props.badgeText === '모집 중'
      ? `${hanghae.red60}`
      : `${neutralDay.gray60}`};
  display: ${(props) => !props.badgeText && 'none'};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.8 : 1)};
`;

export const MobileGnbOverlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  padding-top: 50px;
  background-color: ${neutralDay.gray100};
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  transform: ${({ isOpen }) =>
    isOpen ? 'translate3d(0vw, 0, 0)' : 'translate3d(100vw, 0, 0)'};
  overflow-y: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  ${DESKTOP} {
    display: none;
  }
`;

export const MobileGnbHeader = styled.div<{ isSignIn: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: ${({ isSignIn }) => (isSignIn ? '32px 16px 4px 16px' : '32px 16px')};
  border-bottom: ${({ isSignIn }) =>
    isSignIn ? 'none' : `solid 8px ${neutralDark.gray0}`};
  cursor: pointer;
  margin-bottom: 10px;
`;

export const MobileGnbIconButtonContainer = styled.div`
  display: flex;
  padding: 24px 16px;
  gap: 12px;
  justify-content: space-around;
  border-bottom: 8px solid ${neutralDark.gray0};
`;

export const MobileGnbIconButton = styled(Link)`
  display: flex;
  flex-grow: 1;
  gap: 6px;
  width: max-content;
  align-items: center;
  justify-content: center;
  padding: 10px 10.83px;
`;

export const MobileGnbIconButtonDivider = styled.div`
  align-self: stretch;
  width: 1px;
  background-color: ${neutralDay.gray95};
`;

export const MobileGnbTabContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: 200px;
`;

export const MobileGnbTabContent = styled.div<{
  isOpen: boolean;
  isStudy: boolean;
}>`
  display: flex;
  flex-direction: ${({ isStudy }) => (isStudy ? 'column' : 'row')};
  max-height: ${({ isOpen }) => (isOpen ? getVar('--height') : '0px')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  width: 100%;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
  opacity: 0.8;
  background-color: ${neutralDark.gray0};
  padding: ${({ isOpen }) => (isOpen ? '24px' : '0px 24px')};
  gap: ${({ isStudy }) => (isStudy ? '20px' : '')};

  ul {
    flex-grow: 1;
    margin: 0;
    padding-inline-start: 16px;
  }
`;

export const MobileGnbTabTrigger = styled.button`
  all: unset;
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  box-sizing: border-box;
  width: 100%;
`;

export const BootcampMenuContainer = styled.div``;

export const ArrowChevronDownWrapper = styled.span<{
  isRotated: boolean;
}>`
  rotate: ${({ isRotated }) => (isRotated ? '180deg' : '0')};
  transition: all 0.3s ease-in-out;
`;

export const MenuTitleContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 18px 16px;
  cursor: pointer;
`;

export const MenuTitle = styled.div`
  position: relative;
  display: flex;
  gap: 4px;
`;

export const GnbSubGroupItem = styled(Text)<{
  isActive: boolean;
  disabled?: boolean;
}>`
  width: 100%;
  display: flex;
  gap: 6px;
  align-items: center;
  padding: 11px 0px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &:hover {
    && {
      ${mBody1};
      color: ${hanghae.red100};
    }
  }
`;

export const RedDot = styled.span`
  display: inline-block;
  width: 4px;
  height: 4px;
  background-color: ${hanghae.red100};
  border-radius: 50%;
  position: relative;
  top: 2px;
`;
