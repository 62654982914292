import { Divider, Flex, neutralDay, Text } from '@teamsparta/design-system';
import { InView, Separated, When } from '@teamsparta/react';
import { isEmptyArray } from '@teamsparta/utils';
import Image from 'next/image';
import { Fragment } from 'react';

import Only from '@/components/common/Only';
import { Skeleton } from '@/components/common/skeleton';
import { Suspense } from '@/components/common/Suspense';
import { createCDNUrl } from '@/lib/utils/url';

import { Categories } from '../Categories';
import { OngoingPollPosts } from '../OngoingPollPosts';
import { Post } from '../Post';
import { useCategory, useInfiniteFeed, useRestoreScroll } from './logic';

export function Feed() {
  const { category, handleChangeCategory } = useCategory();
  const { posts, isFetchingNextPage, loadMore } = useInfiniteFeed(category);

  useRestoreScroll(category);

  return (
    <Flex.Column gap={{ mobile: 0, desktop: 16 }} fullWidth>
      <Categories value={category} onChange={handleChangeCategory} />
      <When condition={!isEmptyArray(posts)} fallback={<Empty />}>
        <Flex.Column gap={{ mobile: 0, desktop: 16 }} fullWidth>
          <Separated
            with={
              <Only.Mobile>
                <Divider color={neutralDay.gray95} />
              </Only.Mobile>
            }
          >
            {posts
              ?.slice(0, 5)
              .map((post) => (
                <Post selectedCategory={category} key={post._id} {...post} />
              ))}
            <Only.Mobile>
              <Suspense clientOnly fallback={<OngoingPollPosts.Skeleton />}>
                <OngoingPollPosts />
              </Suspense>
            </Only.Mobile>
            {posts
              ?.slice(5)
              .map((post) => (
                <Post selectedCategory={category} key={post._id} {...post} />
              ))}

            <When
              condition={!isFetchingNextPage}
              fallback={<Feed.Skeleton withCategories={false} />}
            >
              <InView onEnter={loadMore}>
                <div />
              </InView>
            </When>
          </Separated>
        </Flex.Column>
      </When>
    </Flex.Column>
  );
}

function Empty() {
  return (
    <Flex.Column
      padding='80px 0'
      justify='center'
      align='center'
      fullWidth
      gap={12}
    >
      <Only.Mobile>
        <Image
          src={createCDNUrl('/hanghae99/community/writing-hand.webp')}
          alt=''
          width={24}
          height={24}
        />
      </Only.Mobile>
      <Only.Desktop>
        <Image
          src={createCDNUrl('/hanghae99/community/writing-hand.webp')}
          alt=''
          width={30}
          height={30}
        />
      </Only.Desktop>
      <Text
        as='span'
        font='mTitle2'
        color={neutralDay.gray40}
        style={{ whiteSpace: 'pre-wrap' }}
        align='center'
      >
        {'아직 글이 없어요\n첫 글을 작성해 보세요'}
      </Text>
    </Flex.Column>
  );
}

function _Skeleton({ withCategories = true }: { withCategories?: boolean }) {
  return (
    <Flex.Column gap={{ mobile: 0, desktop: 16 }} fullWidth>
      {withCategories && <Categories />}
      <Flex.Column gap={{ mobile: 0, desktop: 16 }} fullWidth>
        {[0, 1, 2].map((order) => (
          <Fragment key={order}>
            <Only.Desktop>
              <When
                condition={order % 2 === 0}
                fallback={
                  <Skeleton style={{ width: '100%', height: '158px' }} />
                }
              >
                <Skeleton
                  key={order}
                  style={{ width: '100%', height: '266px' }}
                />
              </When>
            </Only.Desktop>
            <Only.Mobile>
              <Flex fullWidth padding='24px 16px'>
                <When
                  condition={order % 2 === 0}
                  fallback={
                    <Skeleton style={{ width: '100%', height: '101px' }} />
                  }
                >
                  <Skeleton
                    key={order}
                    style={{ width: '100%', height: '203px' }}
                  />
                </When>
              </Flex>
            </Only.Mobile>
          </Fragment>
        ))}
      </Flex.Column>
    </Flex.Column>
  );
}

Feed.Skeleton = _Skeleton;
