import { useSuspenseInfiniteQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { parseAsStringLiteral, useQueryState } from 'nuqs';
import { useCallback, useEffect } from 'react';

import type { CommunityPostCategory } from '@/constants';
import { COMMUNITY_POST_CATEGORIES } from '@/constants';
import {
  COMMUNITY_POST_QUERY_EXPIRE_TIME,
  communityPostsInfiniteQuery,
} from '@/hooks/react-query/community/post';
import {
  getSessionStorageItem,
  removeSessionStorageItem,
  setSessionStorageItem,
} from '@/lib/utils/session-storage';

export function useCategory() {
  const [category, setCategory] = useQueryState(
    'category',
    parseAsStringLiteral(COMMUNITY_POST_CATEGORIES).withDefault('전체'),
  );

  const handleChangeCategory = useCallback(
    (category: CommunityPostCategory) => {
      setCategory(category);
      setSessionStorageItem('category', category);

      window.scrollTo(0, 0);
      removeSessionStorageItem(SCROLL_Y_STORAGE_KEY);
    },
    [setCategory],
  );

  return { category, handleChangeCategory };
}

export function useInfiniteFeed(category: CommunityPostCategory) {
  const {
    data: { pages },
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useSuspenseInfiniteQuery(communityPostsInfiniteQuery({ category }));
  const posts = pages.flatMap((page) => page.posts) ?? [];

  const loadMore = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  return { posts, isFetchingNextPage, loadMore };
}

type StoredPosition = {
  y: number;
  timestamp: number;
  postId: string;
  category: CommunityPostCategory;
};

const SCROLL_Y_STORAGE_KEY = 'community-feed-scroll-y';

export function useRestoreScroll(category: CommunityPostCategory) {
  const router = useRouter();

  // 페이지 이동 시 스크롤 위치 저장
  useEffect(() => {
    function handleRouteChange() {
      setSessionStorageItem(SCROLL_Y_STORAGE_KEY, {
        y: window.scrollY,
        timestamp: Date.now(),
        category,
      });
    }

    router.events.on('routeChangeStart', handleRouteChange);
    return () => router.events.off('routeChangeStart', handleRouteChange);
  }, [category, router.events]);

  // 스크롤 위치 복원
  useEffect(() => {
    const storedPosition =
      getSessionStorageItem<StoredPosition>(SCROLL_Y_STORAGE_KEY);
    if (!storedPosition) {
      return;
    }

    const isExpired =
      Date.now() - storedPosition.timestamp > COMMUNITY_POST_QUERY_EXPIRE_TIME;
    if (isExpired || storedPosition.category !== category) {
      removeSessionStorageItem(SCROLL_Y_STORAGE_KEY);
      return;
    }

    window.scrollTo(0, storedPosition.y);
    removeSessionStorageItem(SCROLL_Y_STORAGE_KEY);
  }, [category]);

  // '목록으로' 버튼을 눌렀을 때나 뒤로가기를 했을 때가 아니라 새로고침 시에는 스크롤 최상단으로 이동
  useEffect(() => {
    window.history.scrollRestoration = 'manual';
    return () => {
      window.history.scrollRestoration = 'auto';
    };
  }, []);
}
