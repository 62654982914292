import type { PrimitiveAtom } from 'jotai';
import { atom } from 'jotai';
import { getDefaultStore } from 'jotai/vanilla';

import { type OnlineUserInfo } from '@/components/providers/OnlineUserInfoProvider';

/**
 * @deprecated onlineUserInfoAtom을 사용해 주세요.
 */
export const onlineUserIdAtom = atom<string>('');

export const onlineUserInfoAtom = atom<OnlineUserInfo | null>(
  null,
) as PrimitiveAtom<OnlineUserInfo | null>;

export const tokenAtom = atom<{ isLoading: boolean; token: string | null }>({
  isLoading: true,
  token: null,
});

export const authStore = getDefaultStore();
authStore.set(onlineUserInfoAtom, null);
authStore.set(tokenAtom, { isLoading: true, token: null });
