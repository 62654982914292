import { useCallback } from 'react';

import type { PollOption } from '@/api/community/post';

export function useCalculatePoll(pollOptions?: PollOption[]) {
  const totalSelectedCount =
    pollOptions?.reduce((acc, { selectedCount }) => acc + selectedCount, 0) ??
    0;

  const pollOptionsWithPercentage =
    pollOptions?.map((pollOption) => ({
      ...pollOption,
      percentage: (pollOption.selectedCount / (totalSelectedCount || 1)) * 100,
    })) ?? [];

  const mostSelectedOption = pollOptionsWithPercentage.reduce((acc, curr) =>
    acc.selectedCount > curr.selectedCount ? acc : curr,
  );

  const isAllSelectedCountSame = pollOptions?.every(
    (pollOption) =>
      pollOption.selectedCount === mostSelectedOption.selectedCount,
  );

  const isMostSelectedOption = useCallback(
    (optionText: string) =>
      optionText === mostSelectedOption.optionText && !isAllSelectedCountSame,
    [isAllSelectedCountSame, mostSelectedOption.optionText],
  );

  return {
    pollOptionsWithPercentage,
    isMostSelectedOption,
  };
}

export function formatPercentage(percentage: number) {
  return `${
    percentage.toFixed(1).endsWith('.0')
      ? percentage.toFixed(0)
      : percentage.toFixed(1)
  }%`;
}
