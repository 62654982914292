import styled from '@emotion/styled';
import {
  hanghae,
  mBody2,
  mCaption2,
  mCaption3,
  neutralDay,
  Text,
  wBody1,
  wBody2,
} from '@teamsparta/design-system';

import { device } from '@/styles/themes';
import { applyOpacityToHexColor } from '@/styles/utils/applyOpacityToHexColor';

export const Wrapper = styled.div<{
  fixed: boolean;
  scrollY: number;
  scrollDirection: string;
}>`
  height: ${({ fixed, scrollY, scrollDirection }) => {
    if (fixed) {
      return '50px';
    }
    return scrollY === 0 || scrollDirection === 'down' ? '0' : '50px';
  }};
  ${({ fixed, scrollY }) =>
    fixed || scrollY > 0
      ? 'background: rgba(44, 45, 46, 0.8);backdrop-filter: blur(10px);'
      : ''};
  position: fixed;
  z-index: 20;
  width: 100%;
  transition: all 0.4s ease;
  top: 0;

  @media ${device.desktop} {
    height: ${({ fixed, scrollY, scrollDirection }) => {
      if (fixed) {
        return '60px';
      }
      return scrollY === 0 || scrollDirection === 'down' ? '0' : '60px';
    }};
  }
`;

export const Container = styled.nav<{
  scrollY: number;
  isMobileGnbOpen: boolean;
}>`
  display: flex;
  width: 100%;
  backdrop-filter: ${({ scrollY, isMobileGnbOpen }) =>
    scrollY === 0 && !isMobileGnbOpen ? 'null' : `blur(10px)`};
  background-color: ${neutralDay.gray100};

  @media ${device.desktop} {
    backdrop-filter: blur(10px);
    background-color: ${neutralDay.gray100};
  }
`;

export const SubWrapper = styled.div<{
  fixed: boolean;
  scrollDirection: string;
}>`
  display: flex;
  width: 100%;
  height: ${({ fixed, scrollDirection }) =>
    fixed || scrollDirection === 'up' ? '50px' : '0'};
  transition: all 0.4s ease;
  align-items: center;
  z-index: 20;
  top: 0;
  justify-content: space-between;
  border-bottom: 1px solid ${neutralDay.gray95};
  background-color: ${neutralDay.gray100};

  @media ${device.desktop} {
    max-width: 1232px;
    height: ${({ fixed, scrollDirection }) =>
      fixed || scrollDirection === 'up' ? '60px' : '0'};
    transition: all 0.4s ease;
    margin: auto;
    border-bottom: none;
  }
`;

export const ImageAndNavBtn = styled.div<{
  fixed: boolean;
  scrollDirection: string;
}>`
  display: ${({ fixed, scrollDirection }) =>
    fixed || scrollDirection === 'up' ? 'flex' : 'none'};
  transition: all 0.4s ease;
  width: 100%;
  align-items: center;
  padding-inline: 16px;
  gap: 32px;
`;

export const ImageWrapper = styled.div`
  position: relative;
  cursor: pointer;
  width: 75px;
  height: 24px;

  @media ${device.desktop} {
    position: relative;
    width: 83.69px;
    height: 26px;
    left: 0;
  }
`;

export const PageBtnList = styled.div`
  display: none;
  @media ${device.desktop} {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    margin: 0;
  }
`;

export const PageBtnEachSide = styled.div`
  display: flex;
  align-items: center;
  @media ${device.desktop} {
    gap: 30px;
  }
`;
export const PageBtnListItem = styled.li<{ width?: number }>`
  display: flex;
  align-items: center;
  position: relative;
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  gap: 6px;
  cursor: pointer;
`;

export const StyledLink = styled.div<{
  show?: boolean;
  isClicked?: boolean;
  width?: number;
}>`
  cursor: pointer;
  display: flex;
  gap: 4px;
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  ${mBody2};
  line-height: 21px;
  white-space: nowrap;

  &:hover {
    color: ${neutralDay.white};
    font-weight: bold;
  }

  @media ${device.desktop} {
    display: flex;
    &:hover .toolTip {
      display: flex;
      align-items: center;
    }
    ${wBody2};
    height: 60px;
    align-items: center;

    color: ${({ isClicked, show }) =>
      isClicked || show
        ? `${neutralDay.white}; font-weight: bold`
        : `${neutralDay.gray30}`};
  }
`;

export const ArrowDropDownLineWrapper = styled.span<{ open: boolean }>`
  rotate: ${({ open }) => (open ? '180deg' : '0deg')};
  display: flex;
`;

export const RedDot = styled.span`
  display: inline-block;
  width: 4px;
  height: 4px;
  background-color: ${hanghae.red100};
  border-radius: 50%;
  top: -8px;
  position: relative;
`;

export const LogoutBtn = styled.a<{ scrollDirection: string }>`
  min-width: 24px;
  display: ${({ scrollDirection }) =>
    scrollDirection === 'down' ? 'none' : 'inherit'};
  ${mCaption2};
  color: #c7d2d8;

  @media ${device.desktop} {
    width: fit-content;
    min-width: 48px;
    display: ${({ scrollDirection }) =>
      scrollDirection === 'down' ? 'none' : 'inherit'};
    ${wBody2};
    color: ${neutralDay.gray30};
    cursor: pointer;
  }
`;
export const RoundInfoBadge = styled.div<{
  isNew: boolean;
  isDisabled?: boolean;
  badgeText: string;
}>`
  ${mCaption3};
  line-height: 1;
  font-size: 11px;
  display: flex;
  align-items: center;
  padding: 1px 4px;
  height: 21px;
  border-radius: 4px;
  background-color: ${(props) =>
    props.badgeText === '모집 중'
      ? applyOpacityToHexColor(hanghae.red60, 0.2)
      : applyOpacityToHexColor(neutralDay.gray60, 0.2)};
  color: ${(props) =>
    props.badgeText === '모집 중'
      ? `${hanghae.red60}`
      : `${neutralDay.gray60}`};
  display: ${(props) => !props.badgeText && 'none'};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.8 : 1)};
`;

export const GnbRootGroupContainer = styled.div``;

export const GnbGroupExpandOverlay = styled.div<{ isOpen: boolean }>`
  position: absolute;
  inset: 0;
  width: 100%;
  height: ${({ isOpen }) => (isOpen ? '100vh' : '0')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  margin-top: 60px;
  background-color: ${applyOpacityToHexColor(neutralDay.gray100, 0.4)};
`;

export const GnbGroupExpandOverlayContentWrapper = styled.div<{
  isOpen: boolean;
  contentHeight: number;
}>`
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 40px 0 60px 0;
  background-color: ${neutralDay.gray95};
  box-shadow: 0 20px 24px 0 rgba(20, 22, 23, 0.24);
  overflow: hidden;
  height: ${({ isOpen, contentHeight }) =>
    isOpen ? `${contentHeight + 100}px` : '1px'};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition:
    height 0.3s ease-in-out,
    opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out;
`;

export const GnbGroupExpandOverlayContent = styled.div`
  display: flex;
  min-width: 968px;
  height: 100%;
  gap: 40px;
`;

export const GnbGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  min-width: 190px;
`;

export const GnbGroupDivider = styled.div`
  width: 1px;
  flex-shrink: 0;
  align-self: stretch;
  background-color: ${neutralDay.gray90};
`;

export const GnbSubGroupItem = styled(Text)<{
  isActive: boolean;
  disabled?: boolean;
}>`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &:hover {
    && {
      ${wBody1};
      color: ${hanghae.red100};
    }
  }
`;
