import { useQueryClient } from '@tanstack/react-query';

import type { CommunityLikeType } from '@/api/community/like';
import { postCommunityLike } from '@/api/community/like';
import type { CommunityPost } from '@/api/community/post';

import { useAuthMutation } from '../common/auth';
import { communityPostCommentQueryKey } from './comment';
import { communityPostQueryKey } from './post';

const communityLikeQueryKey = {
  post: ({
    targetId,
    targetType,
  }: {
    targetId: string;
    targetType: CommunityLikeType;
  }) => ['community', 'like', targetId, targetType] as const,
};

/**
 * @todo community 낙관적 업데이트 혹은 투표처럼 부분 업데이트 추가
 */
export function usePostCommunityPostLike({ postId }: { postId: string }) {
  return useAuthMutation({
    mutationKey: communityLikeQueryKey.post({
      targetId: postId,
      targetType: 'Post',
    }),
    mutationFn: ({ postId }: { postId: string }) =>
      postCommunityLike({ targetId: postId, targetType: 'Post' }),
  });
}

export function usePostCommunityPostDetailLike({ postId }: { postId: string }) {
  const queryClient = useQueryClient();

  return useAuthMutation({
    mutationKey: communityLikeQueryKey.post({
      targetId: postId,
      targetType: 'Post',
    }),
    mutationFn: ({ postId }: { postId: string }) =>
      postCommunityLike({ targetId: postId, targetType: 'Post' }),
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: communityPostQueryKey.detail(postId),
      });

      const previousPostDetail = queryClient.getQueryData<CommunityPost>(
        communityPostQueryKey.detail(postId),
      );

      if (!previousPostDetail) {
        return;
      }

      const newPostDetail = {
        ...previousPostDetail,
        likesCount: previousPostDetail.isMyLike
          ? previousPostDetail.likesCount - 1
          : previousPostDetail.likesCount + 1,
        isMyLike: !previousPostDetail.isMyLike,
      };

      queryClient.setQueryData(
        communityPostQueryKey.detail(postId),
        newPostDetail,
      );

      return { previousPostDetail };
    },
    onError: (error, { postId }, context) => {
      queryClient.setQueryData(
        communityPostQueryKey.detail(postId),
        // @ts-ignore
        context.previousPostDetail,
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: communityPostQueryKey.detail(postId),
      });
    },
  });
}

/**
 * @todo community 낙관적 업데이트 추가
 */
export function usePostCommunityCommentLike({
  postId,
  commentId,
}: {
  postId: string;
  commentId: string;
}) {
  const queryClient = useQueryClient();

  return useAuthMutation({
    mutationKey: communityLikeQueryKey.post({
      targetId: commentId,
      targetType: 'Comment',
    }),
    mutationFn: ({ commentId }: { commentId: string }) =>
      postCommunityLike({ targetId: commentId, targetType: 'Comment' }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: communityPostCommentQueryKey.comments({
          postId,
          sortBy: 'liked',
        }),
      });
      await queryClient.invalidateQueries({
        queryKey: communityPostCommentQueryKey.comments({
          postId,
          sortBy: 'created',
        }),
      });
    },
  });
}

export function usePostCommunitySubCommentLike({
  postId,
  subCommentId,
}: {
  postId: string;
  subCommentId: string;
}) {
  const queryClient = useQueryClient();

  return useAuthMutation({
    mutationKey: communityLikeQueryKey.post({
      targetId: subCommentId,
      targetType: 'SubComment',
    }),
    mutationFn: ({ subCommentId }: { subCommentId: string }) =>
      postCommunityLike({ targetId: subCommentId, targetType: 'SubComment' }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: communityPostCommentQueryKey.comments({
          postId,
          sortBy: 'liked',
        }),
      });
      queryClient.invalidateQueries({
        queryKey: communityPostCommentQueryKey.comments({
          postId,
          sortBy: 'created',
        }),
      });
    },
  });
}
