import { Flex, hanghae, neutralDay, Text } from '@teamsparta/design-system';

import type { PollOption } from '@/api/community/post';

import { formatPercentage, useCalculatePoll } from './logic';
import * as S from './style';

interface Props {
  pollOptions?: PollOption[];
}

export function DidPoll({ pollOptions }: Props) {
  const { pollOptionsWithPercentage, isMostSelectedOption } =
    useCalculatePoll(pollOptions);

  return (
    <Flex.Column fullWidth gap={6}>
      {pollOptionsWithPercentage.map(
        ({ optionText, selectedCount, percentage }, index) => (
          <Flex.Row
            key={`${optionText}-${index}`}
            fullWidth
            justify='between'
            padding='10px 12px'
            style={{
              position: 'relative',
              overflow: 'hidden',
              border: `1px solid ${
                isMostSelectedOption(optionText)
                  ? 'rgba(255, 92, 92, 0.40)'
                  : neutralDay.gray90
              }`,
              borderRadius: '999px',
            }}
          >
            <Text
              as='span'
              font='mBody3'
              color={neutralDay.gray30}
              style={{ zIndex: 1 }}
            >
              {optionText}
            </Text>
            <Text
              as='span'
              font='mBody3'
              color={
                isMostSelectedOption(optionText)
                  ? hanghae.red60
                  : neutralDay.gray60
              }
              style={{ zIndex: 1 }}
            >
              {formatPercentage(percentage)} ({selectedCount})
            </Text>
            <S.PollProgressBar
              percentage={percentage}
              isMostSelectedOption={isMostSelectedOption(optionText)}
            />
          </Flex.Row>
        ),
      )}
    </Flex.Column>
  );
}
