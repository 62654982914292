import {
  infiniteQueryOptions,
  keepPreviousData,
  queryOptions,
  useQueryClient,
} from '@tanstack/react-query';

import type {
  CommunityPostCommentSortBy,
  GetCommunityPostCommentsResponseDto,
} from '@/api/community/comment';
import {
  deleteCommunityPostComment,
  getCommunityPostComments,
  patchCommunityPostComment,
  postCommunityPostComment,
} from '@/api/community/comment/comment';

import { useAuthMutation } from '../common/auth';
import { communityPostQueryKey } from './post';

export const communityPostCommentQueryKey = {
  all: () => ['community-comment'] as const,
  comments: ({
    postId,
    sortBy = DEFAULT_SORT_BY,
  }: {
    postId: string;
    sortBy?: CommunityPostCommentSortBy;
  }) => [...communityPostCommentQueryKey.all(), postId, sortBy] as const,
  post: (postId: string) =>
    [...communityPostCommentQueryKey.all(), postId] as const,
  delete: (commentId: string) =>
    [...communityPostCommentQueryKey.all(), 'delete', commentId] as const,
  patch: (commentId: string) =>
    [...communityPostCommentQueryKey.all(), 'patch', commentId] as const,
};

export const communityPostCommentsQuery = ({
  postId,
  queryFn = () => getCommunityPostComments({ postId }),
}: {
  postId: string;
  queryFn?: () => Promise<GetCommunityPostCommentsResponseDto>;
}) =>
  queryOptions({
    queryKey: communityPostCommentQueryKey.comments({ postId }),
    queryFn,
    enabled: Boolean(postId),
    refetchOnWindowFocus: false,
  });

export const DEFAULT_SORT_BY: CommunityPostCommentSortBy = 'created';
export const communityPostCommentsInfiniteQuery = ({
  postId,
  sortBy = DEFAULT_SORT_BY,
}: {
  postId: string;
  sortBy?: CommunityPostCommentSortBy;
}) =>
  infiniteQueryOptions({
    queryKey: communityPostCommentQueryKey.comments({ postId, sortBy }),
    queryFn: ({ pageParam }: { pageParam: string | null }) =>
      getCommunityPostComments({
        cursor: pageParam ?? undefined,
        postId,
        sortBy,
      }),
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    initialPageParam: null,
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
  });

export function usePostCommunityPostComment(postId: string) {
  const queryClient = useQueryClient();

  return useAuthMutation({
    mutationKey: communityPostCommentQueryKey.post(postId),
    mutationFn: postCommunityPostComment,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: communityPostQueryKey.detail(postId),
      });
      await queryClient.invalidateQueries({
        queryKey: communityPostCommentQueryKey.comments({
          postId,
          sortBy: 'liked',
        }),
      });
      await queryClient.invalidateQueries({
        queryKey: communityPostCommentQueryKey.comments({
          postId,
          sortBy: 'created',
        }),
      });
    },
  });
}

export function useDeleteCommunityPostComment({
  postId,
  commentId,
}: {
  postId: string;
  commentId: string;
}) {
  const queryClient = useQueryClient();

  return useAuthMutation({
    mutationKey: communityPostCommentQueryKey.delete(commentId),
    mutationFn: deleteCommunityPostComment,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: communityPostQueryKey.detail(postId),
      });
      queryClient.invalidateQueries({
        queryKey: communityPostCommentQueryKey.comments({
          postId,
          sortBy: 'liked',
        }),
      });
      queryClient.invalidateQueries({
        queryKey: communityPostCommentQueryKey.comments({
          postId,
          sortBy: 'created',
        }),
      });
    },
  });
}

export function usePatchCommunityPostComment({
  postId,
  commentId,
}: {
  postId: string;
  commentId: string;
}) {
  const queryClient = useQueryClient();

  return useAuthMutation({
    mutationKey: communityPostCommentQueryKey.patch(commentId),
    mutationFn: patchCommunityPostComment,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: communityPostQueryKey.detail(postId),
      });
      queryClient.invalidateQueries({
        queryKey: communityPostCommentQueryKey.comments({
          postId,
          sortBy: 'liked',
        }),
      });
      queryClient.invalidateQueries({
        queryKey: communityPostCommentQueryKey.comments({
          postId,
          sortBy: 'created',
        }),
      });
    },
  });
}
