import { useIsClient } from '@/hooks';
import { htmlToText, sanitizeHtml, truncateText } from '@/lib/utils/html';

export function useSafePreviewContent(content: string) {
  const isClient = useIsClient();

  if (!isClient) {
    return content;
  }

  const sanitizedContent = sanitizeHtml(content).replace(
    /<img[^>]*>/g,
    ' (사진) ',
  );
  const contentText = htmlToText(sanitizedContent);
  const truncatedContent = truncateText({
    text: contentText ?? '',
    maxLength: 150,
  });

  return truncatedContent;
}
