import { COURSE_LANDING_URL } from '@/constants';

export const employmentCourses: HubCourse[] = [
  {
    title: '취업 리부트 코스',
    hashTag: '#개발자 취업 재도전',
    desc: '프로젝트부터 면접까지, 핵심만 담은 커리큘럼.\n취업 성공 전까지 비용은 받지 않습니다.',
    href: COURSE_LANDING_URL.HANGHAE_JOB_SUPPORT,
    outLink: true,
  },
];

export const workerCourses: HubCourse[] = [
  {
    title: '플러스 백엔드 코스',
    hashTag: '#백엔드 주니어 역량 강화',
    desc: '견고한 웹 서버 개발부터 장애 대응까지.\n개발자 커리어, 도전을 넘어 도약으로.',
    href: COURSE_LANDING_URL.HANGHAE_PLUS_BACKEND,
    outLink: true,
    isDisabled: false,
  },
  {
    title: '플러스 프론트엔드 코스',
    hashTag: '#프론트엔드 주니어 역량 강화',
    desc: '핵심 개념 실습부터 견고한 설계, 최적화까지.\n구현을 넘어, 열 배 임팩트 내는 개발자로.',
    href: COURSE_LANDING_URL.HANGHAE_PLUS_FRONTEND,
    outLink: true,
    isDisabled: false,
  },
  {
    title: '플러스 AI 코스',
    hashTag: '#현직 개발자 역량 강화',
    desc: '직장과 병행하며, 8주 몰입으로\nAI 기초부터 고도화된 서비스 상용화까지.',
    href: COURSE_LANDING_URL.HANGHAE_PLUS_AI,
    outLink: true,
    isDisabled: false,
  },
  {
    title: '백엔드 커리어 코칭',
    hashTag: '#백엔드 주니어 이직',
    desc: '빅테크 시니어의 1:1 맞춤 코칭으로\n역량 강화 및 이직 성공까지.',
    href: COURSE_LANDING_URL.HANGHAE_BACKEND_CAREER_COACHING,
    outLink: true,
    isDisabled: false,
  },
];

export const _99ClubCourses: HubCourse[] = [
  {
    title: '코딩 테스트 스터디',
    hashTag: '#1일 1코테 습관 형성',
    desc: '현직 개발자와 함께 코딩 테스트 대비부터\nTIL 작성 습관까지.',
    href: COURSE_LANDING_URL['99CLUB_CODING_TEST'],
    outLink: true,
    isDisabled: false,
  },
  {
    title: '알고리즘 강의 스터디',
    hashTag: '#알고리즘 이론 5주 정복',
    desc: '강의 기반으로 주요 알고리즘을 학습하고\n기술 면접, 코테 대비까지 한 번에.',
    href: COURSE_LANDING_URL['99CLUB_ALGORITHM_STUDY'],
    outLink: true,
    isDisabled: false,
  },
];

interface HubCourse {
  title: string;
  hashTag: string;
  desc: string;
  href: string;
  outLink?: boolean;
  isDisabled?: boolean;
}

interface HubCourseGroup {
  title: string;
  courses: HubCourse[];
}

export const HUB_COURSES: HubCourseGroup[] = [
  { title: '개발자 취업을 꿈꾼다면', courses: employmentCourses },
  { title: '현직 개발자라면', courses: workerCourses },
  { title: '가벼운 스터디부터, 99클럽', courses: _99ClubCourses },
];
