import type { INNOVATION } from '@/constants';
import {
  BACKEND_CAREER_COACHING,
  CLUB_DOCKER_STUDY,
  CLUB_KUBERNETES_STUDY,
  CLUB_NEXT_STUDY,
  CLUB_TDD_STUDY,
  FRONTEND_CAREER_COACHING,
  PLUS_AI,
  PLUS_BACKEND,
  PLUS_FRONTEND,
  REBOOT,
  SHORT_TERM_SKILL_UP,
} from '@/constants';
import type { CourseType } from '@/types';

export const COURSE_LANDING_URL: Record<
  Exclude<CourseType, typeof INNOVATION>,
  string
> = {
  [PLUS_BACKEND]: `/plus/be`,
  [PLUS_FRONTEND]: `/plus/fe`,
  [PLUS_AI]: '/plus/ai',
  [REBOOT]: `/reboot`,
  ['99CLUB_CODING_TEST']: `/99club-codingtest`,
  ['99CLUB_ALGORITHM_STUDY']: 'https://99club-algorithm.oopy.io/',
  [BACKEND_CAREER_COACHING]: 'https://hhplus-careercoaching.oopy.io/',
  [FRONTEND_CAREER_COACHING]: 'UNKNOWN',
  [SHORT_TERM_SKILL_UP]: 'https://hh-skillup.oopy.io/',

  [CLUB_NEXT_STUDY]: 'https://99club-nextjs.oopy.io/',
  [CLUB_DOCKER_STUDY]: 'https://99club-docker.oopy.io/',
  [CLUB_KUBERNETES_STUDY]: 'https://99club-kubernetes.oopy.io/',
  [CLUB_TDD_STUDY]: 'https://99club-tdd.oopy.io/',
};
