import { useLoadableRemoteConfig } from '@hackler/react-sdk';

const FALLBACK_VALUE = 'defaultValue';

export const useCustomLoadableRemoteConfig = <T extends any>(
  key: string,
): T | undefined => {
  const { isLoading, remoteConfig } = useLoadableRemoteConfig();
  const value = remoteConfig.get(key, FALLBACK_VALUE);

  if (isLoading || value === FALLBACK_VALUE) {
    return undefined;
  }

  return JSON.parse(value);
};
