import {
  focusManager,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import {
  // TODO: 추후 디자인 시스템 업데이트 후 제거 필요
  DesignSystemProvider as LegacyDesignSystemProvider,
  hanghae,
  neutralDay,
  toast,
  Toaster,
} from '@teamsparta/design-system';
import { StackProvider } from '@teamsparta/stack-core';
import { isClient } from '@teamsparta/utils';
import { Provider as JotaiProvider } from 'jotai';
import { NuqsAdapter } from 'nuqs/adapters/next/pages';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';

import { OnlineUserInfoProvider } from '@/components/providers/OnlineUserInfoProvider';
import { TokenProvider } from '@/hooks';
import {
  RefreshTokenExpiredException,
  RefreshTokenInvalidException,
  RefreshTokenRevokedException,
} from '@/lib/exceptions';
import { Hackle } from '@/lib/sdk';
import { authStore } from '@/store/user';

const breakpoints = {
  desktop: 1024,
  mobile: 0,
};

const theme = {
  mode: 'light',
  colors: {
    light: {
      primary: hanghae.red100,
      secondary: neutralDay.gray100,
      tertiary: neutralDay.gray10,
    },
    dark: {
      primary: hanghae.red100,
      secondary: neutralDay.gray10,
      tertiary: neutralDay.gray90,
    },
  },
} as const;

export function Providers({ children }: { children: ReactNode }) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: (error) => {
            if (
              error instanceof RefreshTokenExpiredException ||
              error instanceof RefreshTokenInvalidException ||
              error instanceof RefreshTokenRevokedException
            ) {
              return;
            }

            toast.error(
              error.message ??
                '문제가 발생했습니다. 고객 센터에 문의 바랍니다.',
              {
                duration: 5000,
              },
            );
            console.error(error);
          },
        }),
      }),
  );

  useEffect(() => {
    focusManager.setEventListener((handleFocus) => {
      if (isClient() && window.addEventListener) {
        const visibilitychangeHandler = () => {
          handleFocus(document.visibilityState === 'visible');
        };
        const focusHandler = () => {
          handleFocus(true);
        };
        const blurHandler = () => {
          handleFocus(false);
        };

        window.addEventListener('visibilitychange', visibilitychangeHandler);
        window.addEventListener('focus', focusHandler);
        window.addEventListener('blur', blurHandler);
        return () => {
          window.removeEventListener(
            'visibilitychange',
            visibilitychangeHandler,
          );
          window.removeEventListener('focus', focusHandler);
          window.removeEventListener('blur', blurHandler);
        };
      }
    });
  }, []);

  return (
    <StackProvider breakpoints={breakpoints} theme='hanghaeLight'>
      <LegacyDesignSystemProvider breakpoints={breakpoints} theme={theme}>
        <QueryClientProvider client={queryClient}>
          <JotaiProvider store={authStore}>
            <OnlineUserInfoProvider>
              <TokenProvider>
                <NuqsAdapter>
                  <Hackle>{children}</Hackle>
                  <Toaster />
                </NuqsAdapter>
              </TokenProvider>
            </OnlineUserInfoProvider>
          </JotaiProvider>
        </QueryClientProvider>
      </LegacyDesignSystemProvider>
    </StackProvider>
  );
}
